import routes from "@/router"
import Cookie from "js-cookie"


routes.beforeEach(async (to, from, next) => {
    handlerCheckIsInviteParam(to)
    next();
})

// 判断页面是否携带邀请加密串，有则保存到cookie中，以便于登录使用
function handlerCheckIsInviteParam(to) {
    let promotesign = to.query.promotesign
    if (promotesign) {
      console.log('携带邀请信息，3天有效');
      Cookie.set('UnionPromoteInfo', promotesign, {
        expires: new Date(new Date().getTime() + 3 * 24 * 3600 * 1000)
      })
    }
  }